<template>
  <div>
    <pre class="line-numbers code-overflow">
      <code class="language-json">
        {
          "baseline_menus": [
            {
              "name": "City Sandwich Shop",
              "reference": "",
              "type": "DELIVERY",
              "categories": [
                {
                  "name": "Sub Sandwiches",
                  "description": "Customized Subs.",
                  "items": [
                    {
                      "name": "Classic Sub",
                      "description": "A sub sandwich with your choice of meat.",
                      "plu": "",
                      "modifiers": [
                        {
                          "name": "Condiments",
                          "description": "Condiments for your sub.",
                          "options": [
                            {
                              "name": "Mustard",
                              "description": "",
                              "plu": "BBB-703",
                              "modifiers": [],
                              "includes": [],
                              "price": 0,
                              "ingredients": [],
                              "reference": "",
                              "gallery": []
                            }
                          ],
                          "pick": {
                            "pick_same_option": false
                          }
                        }
                      ],
                      "includes": [],
                      "price": 0,
                      "ingredients": [],
                      "reference": "",
                      "swap": {
                        "name": "Choose Meat",
                        "options": [
                          {
                            "name": "Beef Sub",
                            "reference": "beef-sub-ABC1",
                            "plu": "ABC1",
                            "price": 1500
                          },
                          {
                            "name": "Smoked Chicken Sub",
                            "reference": "chicken-sub-ABC2",
                            "plu": "ABC2",
                            "price": 1700
                          }
                        ]
                      },
                      "gallery": [
                        {
                          "url": "https://citysandwiches.com/sub.jpg"
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
