<template>
  <div class="pb-15">
    <v-row>
      <v-col cols="12">
        <a class="anchor" id="setup"></a>
        <h1 class="main-section">Walkthrough</h1>
        <p>
          This section will walk you through Pushing a Menu to your test
          restaurant, marking an item as Out of Stock, and placing a test Order.
        </p>
        <div class="alert warning elevation-4">
          <div class="alert-icon">
            <v-icon>fa fa-exclamation</v-icon>
          </div>
          <div class="alert-content">
            <b>BEFORE STARTING</b><br /><br />The
            <b>Item Availability</b> feature and the ability to view menus and
            place orders through a <b>Test Restaurant</b> on your
            <b>Delivery Partner's Website (JustEat, SkipTheDishes, etc.))</b>
            may not be available for all partners. <br /><br />If these features
            are not available for your integration, you may still complete the
            following walkthrough by simply omitting the steps related to these
            features and examining the responses.
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <router-link to="#setup" class="title-anchor">
          <h2>Step 1: Setup and Config</h2>
        </router-link>
        <p>
          In order to complete this tutorial you must first complete the
          following setup steps:
        </p>
        <ol>
          <li>
            Obtain your credential information.
            <p class="sub-description">
              These values can be obtained from your integration support team as
              described in the
              <router-link
                :to="{ path: '/guides/getting-started#prerequisites' }"
                class="title-anchor"
                >Prerequisites</router-link
              >
              section.
            </p>
          </li>
          <li>
            Create an endpoint to recieve order information.
            <p class="sub-description">
              This endpoint must be able to receive POST requests and the
              endpoint's URL must be provided to your integration support team
              for configuration.
            </p>
          </li>
        </ol>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <div class="sticky-code">
          <a class="anchor" id="walkthrough-publish-a-menu"></a>
          <router-link to="#walkthrough-publish-a-menu" class="title-anchor">
            <h2>Step 2: Publish a menu</h2>
          </router-link>
          <p>
            Some features of this walkthrough may not be available to you.
            Please see the <b>BEFORE STARTING</b> note above.
          </p>
          <p>
            In this step we will publish a simple test menu to your
            <b>Test Restaurant</b> using the cURL command.
          </p>
          <ol>
            <li>
              Navigate to your <b>Test Restaurant</b> to see the menu currently
              available.
            </li>
            <li>
              Replace the <code>BRAND_IDENTIFIER</code>,
              <code>LOCATION_ID</code>, and <code>API_KEY</code> placeholders in
              this command with your own values, and run the command in your
              terminal.
            </li>
            <li>
              Navigate back to your <b>Test Restaurant</b>, and refresh to see
              the menu has been updated to match the menu we just pushed!
            </li>
          </ol>
          <br />
          <div class="alert warning elevation-4">
            <div class="alert-icon">
              <v-icon>fa fa-exclamation</v-icon>
            </div>
            <div class="alert-content">
              IMPORTANT: Pushing this test menu will overwrite the menu that is
              currently posted with your Delivery Partner.
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="6">
        <MenuPushCurlCommand />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="walkthrough-item-availability"></a>
        <router-link to="#walkthrough-item-availability" class="title-anchor">
          <h2>Step 3: Update an Item's Availability</h2>
        </router-link>
        <ol>
          <li>
            Navigate to your <b>Staging Restaurant</b> and note which items are
            currently available.
          </li>
          <li>Execute the provided cURL command in your terminal</li>
          <li>
            Navigate back to your <b>Staging Restaurant</b>, and refresh to see
            that the "Secret Ingredient Soup" item is no longer available.
          </li>
          <li>
            Now perform the same cURL command replacing the
            <code>"event": "OUT_OF_STOCK"</code> element with
            <code>"event": "IN_STOCK"</code> to make the item available again!
          </li>
        </ol>
        <br />
      </v-col>
      <v-col md="6">
        <MenuStockCurlCommand />
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <div class="sticky-code">
          <a class="anchor" id="walkthrough-place-an-order"></a>
          <router-link to="#walkthrough-place-an-order" class="title-anchor">
            <h2>Step 4: Place an Order</h2>
          </router-link>
          <ol>
            <li>Navigate to your <b>Staging Restaurant</b>.</li>
            <li>Select an Item and complete the order process.</li>
            <li>
              Check the endpoint you provided to the support team to see the
              order request information.
              <p class="sub-description">
                The request body should be similar to the example given in this
                section.
              </p>
            </li>
          </ol>
        </div>
      </v-col>
      <v-col cols="6">
        <OrderBodyExample />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MenuPushCurlCommand from "@/components/code/MenuCurlCommand.vue";
import MenuStockCurlCommand from "@/components/code/MenuStockCurlCommand.vue";
import OrderBodyExample from "@/components/code/OrderBodyExample.vue";

export default {
  components: {
    MenuPushCurlCommand,
    MenuStockCurlCommand,
    OrderBodyExample,
  },
};
</script>
