<template>
  <div>
    <pre class="line-numbers">
      <code class="language-json">
        {
           "type":"delivery-by-delivery-partner",
           "posLocationId":"{LOCATION_ID}",
           "id":"70dd45e0-1b45-4c9d-ac93-2e54f7bca7a6",
           "location":{
              "id":{LOCATION_ID},
              "timezone":"America/Los_Angeles"
           },
           "driver":{
              "first_name":"",
              "last_name":"",
              "phone_number":""
           },
           "items":[
              {
                 "name":"Secret Ingredient Soup",
                 "description":"The secret ingredient is ... nothing!",
                 "plu":"qwer-asdf-zxcv-qwer",
                 "children":[

                 ],
                 "price":150,
                 "notes": ""
              }
           ],
           "created_at":"1606938604",
           "channel":{
              "id":0000,
              "name":"{your_delivery_provider}"
           },
           "collect_at":"1606938661",
           "collection_notes":"[CONTACTLESS] ",
           "kitchen_notes":"",
           "third_party_order_reference":"4093904",
           "total":250,
           "payment_method":"CARD",
           "tender_type":"",
           "menu_reference":"",
           "payment":{
              "items_in_cart":{
                 "inc_tax":281,
                 "tax":31
              },
              "adjustments":[

              ],
              "final":{
                 "inc_tax":281,
                 "tax":31
              }
           },
           "delivery":{
              "first_name":"{FIRST_NAME}",
              "last_name":"{LAST_NAME}",
              "phone_number":"{PHONE_NUMBER}",
              "line_one":"{ADDRESS}",
              "line_two":"",
              "city":"",
              "postcode":"R3B 0R9",
              "email":"{EMAIL}",
              "coordinates":{
                 "longitude":{LONG},
                 "latitude":{LAT},
                 "longitude_as_string":"{LONG}",
                 "latitude_as_string":"{LAT}"
              },
              "phone_masking_code":""
           },
           "extras":{

           }
        }
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
