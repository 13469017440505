<template>
  <div>
    <pre class="line-numbers code-overflow">
      <code class="language-json">
        {
          "baseline_menus": [
            {
              "name": "Burrito Bill's Delivery Menu",
              "reference": "",
              "type": "DELIVERY",
              "categories": [
                {
                  "name": "Burritos",
                  "description": "",
                  "items": [
                    {
                      "name": "Delivery Burrito",
                      "description": "",
                      "plu": "c46ebf5a-e5b5-4853-aa7c-0c42989f1d4f",
                      "modifiers": [],
                      "includes": [],
                      "price": 0,
                      "ingredients": [],
                      "reference": "",
                      "gallery": []
                    }
                  ]
                }
              ]
            },
            {
              "name": "Burrito Bill's Takeout Menu",
              "reference": "",
              "type": "COLLECTION",
              "availability": {
                "monday": [],
                "tuesday": [],
                "wednesday": [],
                "thursday": [],
                "friday": [],
                "saturday": [],
                "sunday": []
              },
              "categories": [
                {
                  "name": "",
                  "description": "",
                  "items": [
                    {
                      "name": "Takeout Burrito",
                      "description": "",
                      "plu": "9aa4f850-afac-4ec9-a57b-def2100c4826",
                      "modifiers": [],
                      "includes": [],
                      "price": 0,
                      "ingredients": [],
                      "reference": "",
                      "gallery": []
                    }
                  ]
                }
              ]
            }
          ]
        }
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
