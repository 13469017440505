<template>
  <div>
    <pre class="line-numbers code-overflow">
      <code class="language-json">
        {
          "baseline_menus": [
            {
              "name": "Pick Menu",
              "reference": "",
              "type": "DELIVERY",
              "categories": [
                {
                  "name": "Burgers",
                  "description": "",
                  "items": [
                    {
                      "name": "Hamburger",
                      "description": "",
                      "plu": "f37b78df-16c4-4036-96c5-ed61d439ae26",
                      "modifiers": [
                        {
                          "name": "\"Pick Exactly\" Modifier",
                          "description": "The customer will be able to choose exactly 3 options from this group, and they will be able to pick the same option twice.",
                          "options": [],
                          "pick": {
                            "exactly": 3,
                            "pick_same_option": true
                          }
                        },
                        {
                          "name": "\"Pick Range\" Modifier",
                          "description": "The customer will be able to choose between 1 and 2 options from this group, and will be able to pick the same option twice.",
                          "options": [],
                          "pick": {
                            "pick_same_option": true,
                            "range": {
                              "from": 1,
                              "to": 2
                            }
                          }
                        },
                        {
                          "name": "\"Pick Unlimited\" Modifier",
                          "description": "The customer will be able to pick an unlimited amount of options, but will not be able to pick the same option twice.",
                          "options": [],
                          "pick": {
                            "pick_same_option": false
                          }
                        }
                      ],
                      "includes": [],
                      "price": 1000,
                      "ingredients": [],
                      "reference": "",
                      "gallery": []
                    }
                  ]
                }
              ]
            }
          ]
        }
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
