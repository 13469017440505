<template>
  <div>
    <pre class="line-numbers">
      <code class="language-json">
        {
          "success": true
        }
      </code>
    </pre> 
  </div>
</template>

<script>
  import Prism from 'prismjs'

  export default {
    mounted() {
      Prism.highlightAll()
    }
  } 
</script>
