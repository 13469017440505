<template>
  <div>
    <pre class="line-numbers">
      <code class="language-json">
{
  "type": "delivery-by-delivery-partner",
  "posLocationId": "AKZ12",
  "id": "38bbeb45-f520-4438-a44f-0fcdbb29e166",
  "location": {
    "id": 1296,
    "timezone": "Europe/London"
  },
  "driver": {
    "first_name": "John",
    "last_name": "Smith",
    "phone_number": "555-111-3344"
  },
  "items": [
    {
      "name": "Cheeseburger",
      "description": "",
      "plu": "M2",
      "children": [
        {
          "name": "Extra Sauce",
          "description": "",
          "plu": "R3",
          "price": 100
        }
      ],
      "price": 1700,
      "notes": ""
    }
  ],
  "created_at": "1606780145",
  "channel": {
    "id": 32,
    "name": "Just Eat"
  },
  "collect_at": "1606780980",
  "collection_notes": "Driver will be wearing a blue shirt",
  "kitchen_notes": "",
  "third_party_order_reference": "22721763",
  "total": 1800,
  "payment_method": "CARD",
  "tender_type": "Flyt",
  "menu_reference": "",
  "payment": {
    "items_in_cart": {
      "inc_tax": 2160,
      "tax": 360
    },
    "adjustments": [],
    "final": {
      "inc_tax": 2160,
      "tax": 360
    }
  },
  "delivery": {
    "first_name": "****************",
    "last_name": "****************",
    "phone_number": "55555 113 000",
    "line_one": "**********************",
    "line_two": "",
    "city": "*****",
    "postcode": "*****",
    "email": "customer@email.hidden",
    "coordinates": {
      "longitude": -97.13560152293131,
      "latitude": 49.898498728223224,
      "longitude_as_string": "-122.2966",
      "latitude_as_string": "49.8984"
    },
    "phone_masking_code": ""
  },
  "extras": {},
  "promotions": [
    {
      "type": "FREE_ITEM_MIN_BASKET",
      "items": [
        {
          "name": "Crispy Chicken Twist",
          "description": "",
          "plu": "",
          "children": [],
          "price": 419,
          "notes": ""
        }
      ]
    }
  ]
}
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
