<template>
  <div>
    <pre class="line-numbers">
      <code class="language-bash">
        curl \
        --location --request POST '{HOST}/ingest/menu/{BRAND_IDENTIFIER}/locations/{LOCATION_ID}' \
        --header 'Content-Type: application/json' \
        --header 'X-Flyt-API-Key: {API_KEY}' \
        --data-raw \
        '{
          "baseline_menus": []
        }'
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
