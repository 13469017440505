<template>
  <div class="sticky-nav">
    <scrollactive>
      <v-list expand dense class="nav-list">
        <v-list-item-group
          v-for="item in items"
          :key="item.title"
        >
          <template>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            class="sub-item scrollactive-item"
            :to="child.route"
            link
          >
            <v-list-item-content class="item grey--text text--darken-2">
              <v-list-item-title class="sub-item-title font-weight-regular">
                <v-chip label v-text="child.method" class="align-self-center" :class="child.method"></v-chip>
                {{ child.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </scrollactive>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        active: true,
        items: [
          {
            title: "Publish New Menu",
            route: "/docs/menus#post-menu-request",
            method: "post"
          },
          {
            title: "Publish Request",
            route: "/docs/menus#publish-menu-request",
          },
          {
            title: "Publish Response",
            route: "/docs/menus#publish-menu-response",
          },
        ],
        title: "Menus API",
      },
      {
        active: true,
        items: [
          {
            title: "Update Item Availability",
            route: "/docs/item-availability#post-item-availability",
            method: "post"
          },
          {
            title: "Availability Request",
            route: "/docs/item-availability#update-item-availability-request",
          },
          {
            title: "Availability Response",
            route: "/docs/item-availability#update-item-availability-response",
          },
        ],
        title: "Item Availability API",
      },
      {
        active: true,
        items: [
          {
            title: "Receive Order",
            route: "/docs/orders#post-receive-order",
            method: "webhook"
          },
          {
            title: "Order Request",
            route: "/docs/orders#receive-order-request",
          },
          {
            title: "Order Response",
            route: "/docs/orders#receive-order-response",
          },
          {
            title: "Update Async Order Status",
            route: "/docs/async-orders#update-order-status",
            method: "post"
          },
          {
            title: "Status Request",
            route: "/docs/async-orders#update-order-status-request",
          },
          {
            title: "Status Response",
            route: "/docs/async-orders#update-order-status-response",
          },
        ],
        title: "Orders API",
      }
    ],
  }),
};
</script>
