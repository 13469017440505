<template>
  <div class="pb-15">
    <v-row>
      <v-col cols="12">
        <a class="anchor" id="post-menu-request"></a>
        <h1><v-chip label class="post">post</v-chip> Publish New Menu</h1>
        <router-link to="#post-menu-request" class="title-anchor">
          <h2>
            POST<code class="inline"
              >{HOST}/ingest/menu/{BRAND_IDENTIFIER}/locations/{LOCATION_ID}</code
            >
          </h2>
        </router-link>
        <p>
          Used to send a menu to Flyt to be published on your delivery partner
          (JustEat, SkipTheDishes, etc.)
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="publish-menu-request"></a>
        <router-link to="#publish-menu-request" class="title-anchor">
          <h2>Request</h2>
        </router-link>
        <p>
          To send your menus to your delivery partner, send a POST request
          containing the menus in the correct format.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <h3>Headers</h3>
        <AttributesTemplate
          v-for="attribute in headers"
          :key="attribute.Id"
          :attribute="attribute"
        />

        <h3>Path Parameters</h3>
        <AttributesTemplate
          v-for="attribute in pathParams"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <div class="sticky-code">
          <MenuCurlCommandCondensed />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="publish-menu-request-body"></a>
        <h3>Request Body</h3>

        <p>
          The following must be contained in the top-level
          <code>baseline_menus</code> attribute in the request body.
        </p>

        <Required />

        <AttributesTemplate
          v-for="attribute in requestBody"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <h3>Request Examples</h3>
        <v-tabs class="sticky-code">
          <v-tab>Basic Menu</v-tab>
          <v-tab>With Modifiers</v-tab>
          <v-tab>With Swaps</v-tab>
          <v-tab>Pick</v-tab>
          <v-tab>Multiple Menus</v-tab>
          <v-tab-item>
            <RequestBasic />
          </v-tab-item>
          <v-tab-item>
            <RequestModifiers />
          </v-tab-item>
          <v-tab-item>
            <RequestSwaps />
          </v-tab-item>
          <v-tab-item>
            <RequestPick />
          </v-tab-item>
          <v-tab-item>
            <RequestMultiple />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="publish-menu-response"></a>
        <router-link to="#publish-menu-response" class="title-anchor">
          <h2>Response</h2>
        </router-link>
        <p>
          If the menus are valid and the request was accepted, you will receive
          a <code>202 Accepted</code> response.
        </p>
        <p>
          Depending on the partner website used, auto-publish may be available
          and can be requested from your integration team. If auto-publish is
          enabled for your restaurant, the new menu should appear on the
          delivery partner (e.g. SkipTheDishes) website automatically within 5
          minutes.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <h3>Response Body</h3>
        <AttributesTemplate
          v-for="attribute in responseBody"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <v-row>
          <v-col cols="12">
            <h3>Response Examples</h3>
            <v-tabs>
              <v-tab class="code-success">202</v-tab>
              <v-tab class="code-failure">400</v-tab>
              <v-tab class="code-failure">500</v-tab>
              <v-tab-item>
                <Menu202Tab />
              </v-tab-item>
              <v-tab-item>
                <Menu400Tab />
              </v-tab-item>
              <v-tab-item>
                <Menu500Tab />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AttributesTemplate from "@/components/specs/AttributesTemplate.vue";
import Required from "@/components/Required.vue";
import RequestBasic from "@/components/code/PublishMenuRequestBasic.vue";
import RequestSwaps from "@/components/code/PublishMenuRequestSwaps.vue";
import RequestModifiers from "@/components/code/PublishMenuRequestModifiers.vue";
import RequestPick from "@/components/code/PublishMenuRequestPick.vue";
import RequestMultiple from "@/components/code/PublishMenuRequestMultiple.vue";
import Menu202Tab from "@/components/code/Menu202Tab.vue";
import Menu400Tab from "@/components/code/Menu400Tab.vue";
import Menu500Tab from "@/components/code/Menu500Tab.vue";
import MenuCurlCommandCondensed from "@/components/code/MenuCurlCommandCondensed.vue";
import PublishMenusSpec from "@/components/specs/PublishMenus.json";

export default {
  components: {
    AttributesTemplate,
    Required,
    RequestBasic,
    RequestSwaps,
    RequestModifiers,
    RequestPick,
    RequestMultiple,
    Menu202Tab,
    Menu400Tab,
    Menu500Tab,
    MenuCurlCommandCondensed,
  },
  data: () => PublishMenusSpec,
};
</script>
