<template>
  <div>
    <pre class="line-numbers code-overflow">
      <code class="language-json">
        {
          "baseline_menus": [
            {
              "name": "Burger Zone Summer Menu",
              "reference": "burger-zone-summer-5521",
              "type": "DELIVERY",
              "availability": {
                "monday": [
                  "08:00 - 23:59"
                ],
                "tuesday": [
                  "08:00 - 23:59"
                ],
                "wednesday": [
                  "08:00 - 23:59"
                ],
                "thursday": [
                  "08:00 - 23:59"
                ],
                "friday": [
                  "08:00 - 23:59"
                ],
                "saturday": [
                  "08:00 - 23:59"
                ],
                "sunday": [
                  "10:00 - 20:00"
                ]
              },
              "categories": [
                {
                  "name": "Burgers",
                  "description": "Classic burgers.",
                  "items": [
                    {
                      "name": "Veggie Burger",
                      "description": "A classic vegetarian hamburger with all the fixings.",
                      "plu": "ABC1F",
                      "modifiers": [],
                      "includes": [],
                      "price": 1550,
                      "ingredients": [],
                      "reference": "",
                      "gallery": [
                        {
                          "url": "https://www.burgerzone.com/vegburger.jpg"
                        }
                      ],
                      "is_alcoholic": false,
                      "spiciness": "MILD",
                      "dietary_restrictions": [
                        "VEGETARIAN"
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
