import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Guides from '@/views/Guides.vue'
import Docs from '@/views/Docs.vue'
import GoLive from '@/views/GoLive.vue'
import PublishMenus from '@/components/docs/PublishMenus.vue'
import ReceiveOrder from '@/components/docs/ReceiveOrder.vue'
import UpdateOrderStatus from '@/components/docs/UpdateOrderStatus.vue'
import UpdateItemAvailability from '@/components/docs/UpdateItemAvailability.vue'
import GettingStarted from '@/components/guides/GettingStarted.vue'
import Orders from '@/components/guides/Orders.vue'
import Menus from '@/components/guides/Menus.vue'
import ItemAvailability from '@/components/guides/ItemAvailability.vue'
import Walkthrough from '@/components/guides/Walkthrough.vue'
import Faq from '@/views/Faq.vue'
import WhatIsPick from '@/components/faq/WhatIsPick.vue'
import AddingBottleDeposits from '@/components/faq/AddingBottleDeposits.vue'
import AddingAlcoholToSkip from '@/components/faq/AddingAlcoholToSkip.vue'
import AddingSugaredDrinksSkip from '@/components/faq/AddingSugaredDrinksSkip'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      title: 'Connect API',
    }
  },
  {
    path: '/guides',
    component: Guides,
    meta: {
      title: 'Guides'
    },
    children: [
      {
        path: '',
        name: 'guides',
        components: {
          default: GettingStarted,
        },
        meta: {
          title: 'Guides: Getting Started'
        },
      },
      {
        path: '/guides/getting-started',
        components: {
          default: GettingStarted,
        },
        meta: {
          title: 'Guides: Getting Started'
        },
      },
      {
        path: '/guides/orders',
        components: {
          default: Orders,
        },
        meta: {
          title: 'Guides: Orders'
        },
      },
      {
        path: '/guides/menus',
        components: {
          default: Menus,
        },
        meta: {
          title: 'Guides: Menus'
        },
      },
      {
        path: '/guides/item-availability',
        components: {
          default: ItemAvailability,
        },
        meta: {
          title: 'Guides: Item Availability'
        },
      },
      {
        path: '/guides/walkthrough',
        components: {
          default: Walkthrough,
        },
        meta: {
          title: 'Guides: Walkthrough'
        },
      },
    ]
  },
  {
    path: '/docs',
    component: Docs,
    meta: {
      title: 'API Docs'
    },
    children: [
      {
        path: '',
        name: 'docs',
        components: {
          default: PublishMenus,
        },
        meta: {
          title: 'API: Menus'
        },
      },
      {
        path: '/docs/menus',
        components: {
          default: PublishMenus,
        },
        meta: {
          title: 'API: Menus'
        },
      },
      {
        path: '/docs/orders',
        components: {
          default: ReceiveOrder
        },
        meta: {
          title: 'API: Ordering'
        },
      },
      {
        path: '/docs/async-orders',
        components: {
          default: UpdateOrderStatus
        },
        meta: {
          title: 'API: Async Orders'
        },
      },
      {
        path: '/docs/item-availability',
        components: {
          default: UpdateItemAvailability
        },
        meta: {
          title: 'API: Item Availability'
        },
      },
    ]
  },
  {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      title: 'FAQ'
    },
    children: [
      {
        path: '',
        name: 'faq',
        components: {
          default: WhatIsPick,
        },
        meta: {
          title: 'FAQ: What is Pick'
        },
      },
      {
        path: '/faq/what-is-pick',
        components: {
          default: WhatIsPick,
        },
        meta: {
          title: 'FAQ: What is Pick'
        },
      },
      {
        path: '/faq/adding-bottle-deposits',
        components: {
          default: AddingBottleDeposits,
        },
        meta: {
          title: 'FAQ: Adding Bottle Deposits to SkipTheDishes Menu'
        },
      },
      {
        path: '/faq/adding-alcohol-to-skip',
        components: {
          default: AddingAlcoholToSkip,
        },
        meta: {
          title: 'FAQ: Adding Alcohol to SkipTheDishes Menu'
        },
      },
      {
        path: '/faq/adding-sugared-drinks-to-skip',
        components: {
          default: AddingSugaredDrinksSkip,
        },
        meta: {
          title: 'FAQ: Adding Sugared Drinks to SkipTheDishes Menu'
        },
      }
    ]
  },
  {
    path: '/go-live',
    name: 'golive',
    component: GoLive,
    meta: {
      title: 'Go Live'
    }
  },
]

const scrollBehavior = function (to) {
  if (to.hash) {
    return window.scrollTo({ 
      top: document.querySelector(to.hash), 
      behavior: 'smooth' 
    })
  } else {
    return { x: 0, y: 0 }
  }
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior,
  routes,

})

export default router
