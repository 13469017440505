<template>
  <div>
    <pre class="line-numbers">
      <code class="language-json">
        {
          "success": false,
          "errorMessage": "Baseline menus not valid.",
          "validationErrors": [
            {
              "dataPath": "",
              "message": "should have required property 'baseline_menus'"
            }
          ]
        }
      </code>
    </pre>
  </div>
</template>

<script>
  import Prism from 'prismjs'

  export default {
    mounted() {
      Prism.highlightAll()
    }
  } 
</script>
