<template>
  <div class="pb-15">
    <v-row>
      <v-col>
        <h1>How do I add bottle deposits?</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="6">
        <p>To add bottle deposits, add a modifier with one option with the price of a bottle deposit.</p>
        <p>To make sure that the bottle deposit is not taxed add the <code>tax_category</code> property with value <code>NO_TAX</code> on the bottle deposit.</p>
      </v-col>

      <v-col cols="6">
        <div class="sticky-code">
          <pre class="line-numbers">
            <code class="language-json">
              {
                "name": "Bottled Soda",
                "description": "",
                "plu": "5",
                "price": 300,
                "modifiers": [
                  {
                    "name": "Bottle Deposit",
                    "description": "",
                    "pick": { "exactly": 1, "pick_same_option": false },
                    "options": [
                      {
                        "name": "Bottle Deposit",
                        "description": "",
                        "plu": "20",
                        "price": 5,
                        "modifiers": [],
                        "includes": [],
                        "ingredients": [],
                        "tax_category": "NO_TAX"
                      }
                    ]
                  }
                ],
                "includes": [],
                "ingredients": []
              }
            </code>
          </pre>
        </div>
      </v-col>

    </v-row>
  </div>
</template>


<script>
import Prism from 'prismjs'

export default {
  mounted() {
    Prism.highlightAll()
  }
}
</script>
