<template>
  <v-container>
    <TopButton/>
    <v-row>
      <v-col sm="4" md="3">
        <GuidesNav/>
      </v-col>
      <v-col sm="8" md="9" class="pl-6">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TopButton from "@/components/TopButton.vue"
import GuidesNav from "@/components/guides/GuidesNav.vue"
import Prism from 'prismjs'

export default {
  components: {
    TopButton,
    GuidesNav,
  },
  mounted() {
    Prism.highlightAll();
  },
} 
</script>
