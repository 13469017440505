<template>
  <div>
    <pre class="line-numbers">
        <code class="language-json">
          {
            "event": "IN_STOCK",
            "itemReference": "1234-3324332-23423-234",
            "happenedAt": "2018-10-11T14:56:18.234284+01:00",
            "menuReference": "345345",
          }
          </code></pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
