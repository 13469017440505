<template>
  <div>
    <pre class="line-numbers">
      <code class="language-bash">
        curl \
        --location --request POST '{HOST}/stock/{BRAND_IDENTIFIER}/locations/{LOCATION_ID}/stock-update' \
        --header 'Content-Type: application/json' \
        --header 'X-Flyt-API-Key: {API_KEY}' \
        --data-raw \
        '{
            "event": "OUT_OF_STOCK",
            "itemReference": "123-456-789",
            "happenedAt": "2020-10-11T14:56:18.234284+01:00"
        }'
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>


