<template>
  <v-container>
    <TopButton/>
    <v-row>
      <v-col sm="4" md="3">
        <FaqNav/>
      </v-col>
      <v-col sm="8" md="9" class="pl-6">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TopButton from "@/components/TopButton.vue"
import FaqNav from "@/components/faq/FaqNav.vue"
import Prism from "prismjs";

export default {
  components: {
    TopButton,
    FaqNav
  },
  mounted() {
    Prism.highlightAll();
  },
};
</script>
