<template>
  <div>
    <pre class="line-numbers">
        <code class="language-json">
          {
            "happenedAt": "2020-01-31T11:06:11+00:00"
          }
          </code>
        </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
