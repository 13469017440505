<template>
  <div>
    <pre class="line-numbers">
      <code class="language-bash">
        curl \
        --location --request POST '{HOST}/order/{ORDER_ID}/sent-to-pos-success' \
        --header 'Content-Type: application/json' \
        --header 'X-Flyt-API-Key: {API_KEY}' \
        --data-raw \
        '{
          "happenedAt": "2020-01-31T11:06:11+00:00"
        }'
      </code>
    </pre>
  </div>
</template>

<script>
  import Prism from 'prismjs'

  export default {
    mounted() {
      Prism.highlightAll()
    }
  } 
</script>
