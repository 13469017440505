<template>
  <v-container fluid>
    <TopButton/>
    <v-row>
      <v-col sm="4" md="3">
        <DocsNav />
      </v-col>
      <v-col sm="8" md="9" class="pl-6">
        <router-view />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import DocsNav from "@/components/docs/DocsNav.vue";
import TopButton from "@/components/TopButton.vue"
import Prism from "prismjs";

export default {
  components: {
    TopButton,
    DocsNav
  },
  mounted() {
    Prism.highlightAll();
  },
};
</script>
