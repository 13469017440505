<template>
  <div class="pb-15">
    <v-row>
      <v-col>
        <h1>What is "pick"?</h1>
        <p>When placing an order, a customer chooses an <code>item</code> and then selects the different available <code>options</code>. The <code>options</code> available for an item are defined by the menu, while the way the options are selected is defined by the <code>pick</code> object.</p>
        <p>There are three ways in which a customer can pick options:</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3>Pick: <code>exactly</code></h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <p>Customers can choose an exact number of modifier options for an item as defined in the <code>exactly</code> attribute.</p>
        <p>e.g. A customer orders a coffee and is prompted to "pick" a size. They should be able to choose exactly 1 of the options (small, medium, or large), and should only be able to choose it once.</p>
      </v-col>
      <v-col cols="6">
        <div class="sticky-code">
          <pre class="line-numbers">
            <code class="language-json">
              "pick": {
                "exactly": 1;
                "pick_same_option": true;
              }
            </code>
          </pre>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h3>Pick: <code>range</code></h3>
        <p>Customers can choose a number of modifier options between a range of two integers.</p>
        <p>e.g. A customer orders toast which has a modifier of extras (cheese and tomato), a Pick set to <code>range</code> from <code>0</code> to <code>3</code>, and a <code>pick_same_option</code> of true</p>
        <p>This allows the customer to order toast with cheese and tomato, or 2x cheese and 1 tomato, or 1 cheese and 2x tomato.</p>
      </v-col>
      <v-col cols="6">
        <div class="sticky-code">
            <pre class="line-numbers">
              <code class="language-json">
                "pick": {
                  "range": {
                    "from": 0;
                    "to": 3;
                  }
                  "pick_same_option": true;
                }
              </code>
            </pre>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <h3>Pick: <code>unlimited</code></h3>
        <p>Customers can choose as many modifier options as they'd like, with no limit. The only restriction might be picking the same option more than once if <code>pick_same_option</code> is set to false.</p>
      </v-col>
      <v-col cols="6">
        <div class="sticky-code">
            <pre class="line-numbers">
              <code class="language-json">
                "pick": {
                  "pick_same_option": false;
                }
              </code>
            </pre>
        </div>
      </v-col>
    </v-row>
  </div>
</template>


<script>
import Prism from 'prismjs'

export default {
  mounted() {
    Prism.highlightAll()
  }
}
</script>
