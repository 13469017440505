<template>
  <div class="pb-15">
    <v-row>
      <v-col cols="12">
        <a class="anchor" id="post-receive-order"></a>
        <h1><v-chip label class="webhook">webhook</v-chip> Receive Order</h1>
        <router-link to="#post-receive-order" class="title-anchor">
          <h2>POST <code>.../your/order/endpoint</code></h2>
        </router-link>
        <p>When an order is received for your integrated restaurant on a delivery
          website, Flyt will send this webhook to the endpoint you have provided
          as a POST request.</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="receive-order-request"></a>
        <router-link to="#receive-order-request" class="title-anchor">
          <h2>Request</h2>
        </router-link>
        <p>The endpoint you provide to Flyt must be able to receive orders in the
          following format. The <code>type</code> property will determine
          fulfillment type.</p>
        <p>As an additional security measure, orders can be sent from a static IP address. You can request this feature from your integration team.</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <h3>Headers</h3>
        <AttributesTemplate
          v-for="attribute in headers"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col cols="6"> </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <h3>Request Body</h3>
        <AttributesTemplate
          v-for="attribute in requestBody"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <h3>Request Examples</h3>
        <v-tabs class="sticky-code">
          <v-tab>By Partner</v-tab>
          <v-tab>By Restaurant</v-tab>
          <v-tab>Pick-Up</v-tab>
          <v-tab-item>
            <RequestPartner />
          </v-tab-item>
          <v-tab-item>
            <RequestOperator />
          </v-tab-item>
          <v-tab-item>
            <RequestCollection />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="receive-order-response"></a>
        <router-link to="#receive-order-response" class="title-anchor">
          <h2>Response</h2>
        </router-link>
        <p>To indicate that the order was successfully processed, return a
          <code>200</code> status code with an Order Number. Flyt will notify
          the delivery partner (JustEat, SkipTheDishes, etc.) that the order was successfully processed.</p>
        <p>If you would like to handle the order asynchronously, return a
          <code>202</code> status code. Flyt will place the order in a pending
          state, which will fail after 5 minutes if you do not notify Flyt
          through the <router-link :to="{path:'/docs/async-orders#update-order-status'}">update order status endpoint</router-link>. If this endpoint is not called within 5 minutes, the order will be marked as failed to inject.
        </p>
        <p>Any non-<code>2xx</code> status code will be taken as a failure and
          redirected to the tablet to be hand-keyed.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <h3>Response Body</h3>
        <AttributesTemplate
          v-for="attribute in responseBody"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <v-row>
          <v-col cols="12">
            <h3>Response Examples</h3>
            <v-tabs>
              <v-tab class="code-success">200</v-tab>
              <v-tab class="code-success">202</v-tab>
              <v-tab class="code-failure">400</v-tab>
              <v-tab-item>
                <CodeOrderSuccess />
              </v-tab-item>
              <v-tab-item>
                <CodeOrderSuccess />
              </v-tab-item>
              <v-tab-item>
                <CodeOrder400 />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AttributesTemplate from "@/components/specs/AttributesTemplate.vue";
import CodeOrder400 from "@/components/code/CodeOrder400.vue";
import CodeOrderSuccess from "@/components/code/CodeOrderSuccess.vue";
import ReceiveOrderSpec from "@/components/specs/ReceiveOrder.json";
import RequestCollection from "@/components/code/ReceiveOrderRequestCollection";
import RequestPartner from "@/components/code/ReceiveOrderRequestPartner";
import RequestOperator from "@/components/code/ReceiveOrderRequestOperator";

export default {
  components: {
    AttributesTemplate,
    CodeOrder400,
    CodeOrderSuccess,
    RequestCollection,
    RequestPartner,
    RequestOperator
  },
  data: () => ReceiveOrderSpec,
};
</script>
