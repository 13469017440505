<template>
  <div class="sticky-nav">
    <scrollactive>
      <v-list dense class="nav-list">
        <v-list-item-group
          v-for="item in items"
          :key="item.title"
        >
          <template>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
            v-for="child in item.items"
            :key="child.title"
            class="sub-item scrollactive-item"
            :to="child.route"
            link
          >
            <v-list-item-content class="item grey--text text--darken-2">
                <v-list-item-title v-text="child.title" class="sub-item-title font-weight-regular"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </scrollactive>
  </div>
</template>

<script>
  export default {
    data: () => ({
      items: [
        {
          active: true,
          items: [
            {
              title: 'Overview',
              route: '/guides/getting-started#overview'
            },
            {
              title: 'Prerequisites',
              route: '/guides/getting-started#prerequisites'
            },
          ],
          title: 'Getting Started',
        },
        {
          active: true,
          items: [
            { 
              title: 'What is menu integration',
              route: '/guides/menus#menu-integration'
            },
            {
              title: 'How does it work',
              route: '/guides/menus#how-does-menus-integration-work'
            },
            {
              title: 'Your application',
              route: '/guides/menus#menus-your-application'
            },
          ],
          title: 'Menu Integration',
        },
        {
          active: true,
          items: [
            {
              title: 'What is item availability integration',
              route: '/guides/item-availability#item-availability'
            },
            {
              title: 'How does it work',
              route: '/guides/item-availability#how-does-item-availability-work'
            },
            {
              title: 'Your application',
              route: '/guides/item-availability#your-item-availability-app'
            },
          ],
          title: 'Item Availability',
        },
        {
          active: true,
          items: [
            { 
              title: 'What is order integration',
              route: '/guides/orders#order-integration'
            },
            {
              title: 'How does it work',
              route: '/guides/orders#how-does-orders-integration-work'
            },
            {
              title: 'Your application',
              route: '/guides/orders#your-orders-application'
            },
          ],
          title: 'Order Integration',
        },
        {
          active: true,
          items: [
            {
              title: 'Setup & Config',
              route: '/guides/walkthrough#setup'
            },
            {
              title: 'Publish a Menu',
              route: '/guides/walkthrough#walkthrough-publish-a-menu'
            },
            {
              title: 'Stock Update',
              route: '/guides/walkthrough#walkthrough-item-availability'
            },
            {
              title: 'Order an Item',
              route: '/guides/walkthrough#walkthrough-place-an-order'
            }
          ],
          title: 'Walkthrough',
        },
      ],
    }),
  }
</script>
