<template>
  <v-btn class="top" elevation="2" fab fixed bottom right small v-scroll="onScroll" v-show="fab" @click="toTop"><v-icon class="menu-icon">fas fa-arrow-up</v-icon></v-btn>
</template>

<script>
  export default {
    data: () => ({
      fab: false
    }),
    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset ||   e.target.scrollTop || 0
        this.fab = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      }
    }
  } 
</script>
