<template>
  <div class="pb-15">
    <v-row>
      <v-col>
        <h1>How do I add alcohol to my SkipTheDishes menu?</h1>
        <p>You must have approval from SkipTheDishes (Skip) before adding alcohol to your menu. To ensure that alcohol is handled correctly by Skip and taxed appropriately, you must add the following disclaimers and flag all alcoholic items.</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <p>If your restaurant sells alcohol, you must add the "Age Verification" disclaimer and "Contactless Recommendations" disclaimer as empty categories (no items) as the first category in your menu. Note that the "Age Verification" disclaimer should reflect the local legal age for purchasing alcohol.</p>
      </v-col>
      <v-col cols="6">
        <div class="sticky-code">
          <pre class="line-numbers">
            <code class="language-json">
              {
                "name": "Age Verification 19+",
                "description": "If the order contains alcohol items, the recipient of this order is required to present valid government ID to the courier at the time of delivery. This ID must match the name on the order. If the courier is unable to safely verify your ID, or if this condition or any other condition in the SkipTheDishes Terms of Service is not met, the order will not be delivered and will be subject to a $20 fee.",
                "items": []
              },
              {
                "name": "Contactless Recommendations",
                "description": "If your order contains alcohol items, please present your ID in a way that minimizes person-to-person contact, either by displaying it through a glass door or window, placing it on a surface, or presenting it from a distance.",
                "items": []
              }
            </code>
          </pre>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <p>To make sure items are properly taxed and handled correctly by SkipTheDishes, you must add the <code>tax_category</code> property with value <code>ALCOHOL</code> to every alcoholic item.</p>
      </v-col>
      <v-col cols="6">
        <div class="sticky-code">
          <pre class="line-numbers">
            <code class="language-json">
              {
                "name": "12oz Beer",
                "description": "12oz can of your favourite beer",
                "plu": "5",
                "price": 500,
                "modifiers": [],
                "includes": [],
                "ingredients": [],
                "tax_category": "ALCOHOL"
              }
            </code>
          </pre>
        </div>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import Prism from 'prismjs'

export default {
  mounted() {
    Prism.highlightAll()
  }
}
</script>
