<template>
  <div>
    <pre class="line-numbers">
      <code class="language-bash">
        curl \
        --location --request POST '{HOST}/ingest/menu/{BRAND_IDENTIFIER}/locations/{LOCATION_ID}' \
        --header 'Content-Type: application/json' \
        --header 'X-Flyt-API-Key: {API_KEY}' \
        --data-raw \
        '{
          "baseline_menus": [
            {
              "name": "Getting Started Menu!",
              "reference": "1001",
              "type": "DELIVERY",
              "availability": {
                "monday": ["10:00 - 21:00"],
                "tuesday": ["10:00 - 21:00"],
                "wednesday": ["10:00 - 21:00"],
                "thursday": ["10:00 - 21:00"],
                "friday": ["10:00 - 21:00"],
                "saturday": ["11:00 - 23:00"],
                "sunday": ["12:00 - 20:00"]
              },
              "categories": [
                {
                  "name": "Speciality Soups",
                  "description": "",
                  "items": [
                    {
                      "name": "Secret Ingredient Soup",
                      "description": "The secret ingredient is ... nothing!",
                      "plu": "123-456-789",
                      "price": 150,
                      "modifiers": [],
                      "includes": [],
                      "ingredients": []
                    },
                    {
                      "name": "Chicken Dumplings",
                      "description": "Authentic chicken dumplings",
                      "plu": "234-567-890",
                      "price": 250,
                      "modifiers": [],
                      "includes": [],
                      "ingredients": []
                    }
                  ]
                }
              ]
            }
          ]
        }'
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
