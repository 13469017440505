<template>
  <div>
    <pre class="line-numbers">
      <code class="language-json">
{
  "type": "collection-by-customer",
  "posLocationId": "32c8b122-f599-441f-aa3a-5081fb89f0e8",
  "id": "7494e975-4d24-4ea5-bdd6-306b444ccc51",
  "location": {
    "id": 12642,
    "timezone": "Australia/Sydney"
  },
  "collector": {
    "first_name": "John",
    "last_name": "Doe",
    "phone_number": "020 7946 0504"
  },
  "items": [
    {
      "name": "Cheese Pizza (Large)",
      "description": "",
      "plu": "b048118f-87e7-417b-b45e-b123b2b97a52",
      "children": [],
      "price": 1000,
      "notes": ""
    }
  ],
  "created_at": "1606780145",
  "channel": {
    "id": 59,
    "name": "Menulog"
  },
  "collect_at": "1606780980",
  "collection_notes": "I will be wearing a green dress",
  "kitchen_notes": "Please add extra cheese to the pizza",
  "third_party_order_reference": "122669877",
  "total": 1000,
  "payment_method": "CARD",
  "tender_type": "Flyt",
  "menu_reference": "",
  "payment": {
    "items_in_cart": {
      "inc_tax": 1000,
      "tax": 0
    },
    "adjustments": [],
    "final": {
      "inc_tax": 1000,
      "tax": 0
    }
  },
  "extras": {},
  "promotions": [
    {
      "type": "FREE_ITEM_MIN_BASKET",
      "items": [
        {
          "name": "Crispy Chicken Twist",
          "description": "",
          "plu": "C1",
          "children": [],
          "price": 419,
          "notes": ""
        }
      ]
    }
  ]
}
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
