<template>
  <div class="pb-15">
    <v-row>
      <v-col>
        <h1>
          How do I make sure sugared drinks are taxed in British Columbia?
        </h1>
        <p>
          For restaurants in British Columbia, you must flag all sugared drinks
          on the menu to make sure the appropriate tax is applied.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <p>
          To mark items as sugared drinks, add the
          <code>tax_category</code> property with value
          <code>SUGARED_DRINK</code> to every item that should be taxed as a
          sugared drink.
        </p>
      </v-col>
      <v-col cols="6">
        <div class="sticky-code">
          <pre class="line-numbers">
            <code class="language-json">
              {
                "name": "Sugary Soda",
                "description": "A 12oz can of classic soda.",
                "plu": "1234",
                "price": 200,
                "modifiers": [],
                "includes": [],
                "ingredients": [],
                "tax_category": "SUGARED_DRINK"
              }
            </code>
          </pre>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <p>
          In cases where drinks should be bundled with other items, they must be
          added as a modifier option with the price of the drink reflected in
          the modifier option (not within the item price). The modifier option
          (not the item) should have the <code>tax_category</code> property with
          value <code>SUGARED_DRINK</code> applied.
        </p>
      </v-col>
      <v-col cols="6">
        <div class="sticky-code">
          <pre class="line-numbers">
            <code class="language-json">
              // Incorrect
              {
                "name": "Burger Combo with Soda",
                "description": "Burger combo with a sugary soda.",
                "plu": "55612:1234",
                "price": 1200,
                "modifiers": [],
                "includes": [],
                "ingredients": []
              }

              // Correct
              {
                "name": "Burger Combo",
                "description": "Burger combo with a drink.",
                "plu": "55612",
                "price": 1000,
                "modifiers": [
                  {
                    "name": "Choose your drink",
                    "options": [
                      {
                        "name": "Sugary Soda",
                        "description": "A 12oz can of classic soda.",
                        "plu": "1234",
                        "price": 200,
                        "modifiers": [],
                        "includes": [],
                        "ingredients": [],
                        "tax_category": "SUGARED_DRINK"
                      }
                    ]
                  }
                ],
                "includes": [],
                "ingredients": []
              }
            </code>
          </pre>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
