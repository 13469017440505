<template>
  <div class="sticky-nav">
    <scrollactive>
      <v-list dense class="nav-list">
        <v-list-item-group
            v-for="item in items"
            :key="item.title"
        >
          <template>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item
              v-for="child in item.items"
              :key="child.title"
              class="sub-item scrollactive-item"
              :to="child.route"
              link
          >
            <v-list-item-content class="item grey--text text--darken-2">
              <v-list-item-title v-text="child.title" class="sub-item-title font-weight-regular"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </scrollactive>
  </div>
</template>

<script>
export default {
  data: () => ({
    items: [
      {
        active: true,
        items: [
          {
            title: 'What is "pick"?',
            route: '/faq/what-is-pick'
          },
          {
            title: 'How do I add bottle deposits?',
            route: '/faq/adding-bottle-deposits'
          },
          {
            title: 'How do I add alcohol to my menu?',
            route: '/faq/adding-alcohol-to-skip'
          },
          {
            title: 'How do I add sugared drinks? (British Columbia only)',
            route: '/faq/adding-sugared-drinks-to-skip'
          }
        ],
        title: 'Frequently Asked Questions',
      }
    ],
  }),
}
</script>
