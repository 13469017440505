<template>
  <div class="pb-15">
    <v-row>
      <v-col cols="12">
        <a class="anchor" id="post-item-availability"></a>
        <h1>
          <v-chip label class="post">POST</v-chip> Update Item Availability
        </h1>
        <router-link to="#post-item-availability" class="title-anchor">
          <h2>
            POST<code class="inline"
              >{HOST}/stock/{BRAND_IDENTIFIER}/locations/{LOCATION_ID}/stock-update</code
            >
          </h2>
        </router-link>
        <p>
          Used to update the availability (i.e., in/out of stock) of an item on
          your delivery partner (JustEat, SkipTheDishes, etc.) website.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="update-item-availability-request"></a>
        <router-link
          to="#update-item-availability-request"
          class="title-anchor"
        >
          <h2>Request</h2>
        </router-link>
        <p>
          To update the availability of an item on the menu, send a POST request
          with the PLU of the item. The PLU must be the PLU you provided in the
          menu you sent in the
          <router-link :to="{ path: '/docs/menus#publish-menu-request' }"
            >Publish New Menu</router-link
          >
          request or the status will fail to update.
        </p>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <h3>Headers</h3>
        <AttributesTemplate
          v-for="attribute in headers"
          :key="attribute.Id"
          :attribute="attribute"
        />

        <h3>Path Parameters</h3>
        <AttributesTemplate
          v-for="attribute in pathParams"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <div class="sticky-code">
          <UpdateItemAvailabilityCurlCommand />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <h3>Request Body</h3>

        <Required />

        <AttributesTemplate
          v-for="attribute in requestBody"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <h3>Request Examples</h3>
        <v-tabs class="sticky-code">
          <v-tab>Item Available</v-tab>
          <v-tab>Item Unavailable</v-tab>
          <v-tab-item>
            <UpdateItemAvailabilityAvailable />
          </v-tab-item>
          <v-tab-item>
            <UpdateItemAvailabilityUnavailable />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="update-item-availability-response"></a>
        <router-link
          to="#update-item-availability-response"
          class="title-anchor"
        >
          <h2>Response</h2>
        </router-link>
        <p>
          If the request is valid, you will receive a
          <code>202 Accepted</code> response. The availability change should be
          reflected on the delivery partner website within 5 minutes.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <h3>Response Body</h3>
        <AttributesTemplate
          v-for="attribute in responseBody"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <v-row>
          <v-col cols="12">
            <h3>Response Examples</h3>
            <v-tabs>
              <v-tab class="code-success">202</v-tab>
              <v-tab class="code-failure">400</v-tab>
              <v-tab class="code-failure">500</v-tab>
              <v-tab-item>
                <UpdateItemAvailability202 />
              </v-tab-item>
              <v-tab-item>
                <UpdateItemAvailability400 />
              </v-tab-item>
              <v-tab-item>
                <UpdateItemAvailability500 />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UpdateItemAvailabilityCurlCommand from "@/components/code/UpdateItemAvailabilityCurlCommand.vue";
import UpdateItemAvailability202 from "@/components/code/UpdateItemAvailability202.vue";
import UpdateItemAvailability400 from "@/components/code/UpdateItemAvailability400.vue";
import UpdateItemAvailability500 from "@/components/code/UpdateItemAvailability500.vue";
import UpdateItemAvailabilityAvailable from "@/components/code/UpdateItemAvailabilityAvailable.vue";
import UpdateItemAvailabilityUnavailable from "@/components/code/UpdateItemAvailabilityUnavailable.vue";
import AttributesTemplate from "@/components/specs/AttributesTemplate.vue";
import UpdateItemAvailabilitySpec from "@/components/specs/UpdateItemAvailability.json";
import Required from "@/components/Required.vue";

export default {
  components: {
    AttributesTemplate,
    UpdateItemAvailability202,
    UpdateItemAvailability400,
    UpdateItemAvailability500,
    UpdateItemAvailabilityAvailable,
    UpdateItemAvailabilityUnavailable,
    UpdateItemAvailabilityCurlCommand,
    Required,
  },
  data: () => UpdateItemAvailabilitySpec,
};
</script>
