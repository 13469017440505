import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
  theme: { disable: true },
  icons: { iconfont: 'fa'},
})

export default vuetify;
