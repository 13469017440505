import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify';
import scrollactive from './plugins/scrollActive'

Vue.config.productionTip = false

 router.beforeEach((to, from, next) => {

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    next();
});

new Vue({
  router,
  vuetify,
  scrollactive,
  render: h => h(App)
}).$mount('#app')
