<template>
  <div>
    <pre class="line-numbers">
        <code class="language-json">
          {
            "happenedAt": "2019-04-03T00:56:24+00:00",
            "errorCode": "MENU_ERROR",
            "errorMessage": "Items out of stock"
          }
          </code></pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
