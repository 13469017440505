<template>
  <div>
    <pre class="line-numbers">
        <code class="language-json">
          {
            "message": "order 60298a19-9ef7-4860-bf69-f10fc0a26b21 not found"
          }
          </code>
        </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
