<template>
  <div class="pb-15">
    <v-row>
      <v-col cols="12">
        <a class="anchor" id="update-order-status"></a>
        <h1><v-chip label class="post">post</v-chip> Update Order Status</h1>
        <router-link to="#update-order-status" class="title-anchor">
          <h2>POST <code class="inline">{HOST}/order/{ORDER_ID}/sent-to-pos-{STATUS}</code></h2>
        </router-link>
        <p>Used to update the order status of a pending order. Orders are marked pending when you respond to the <router-link :to="{path:'/docs/orders#post-receive-order'}">receive order webhook</router-link> with a <code>202</code> status; requests to this endpoint for non-pending orders will fail.</p>
        <p>If this endpoint is not called within 5 minutes after an order is marked as pending, the order status will switch to failed to inject.</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="update-order-status-request"></a>
        <router-link to="#update-order-status-request" class="title-anchor">
          <h2>Request</h2>
        </router-link>
        <p>Note that the order status will be updated to succeeded or failed depending on the endpoint called.</p>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <h3>Headers</h3>
        <AttributesTemplate
          v-for="attribute in headers"
          :key="attribute.Id"
          :attribute="attribute"
        />

        <h3>Path Parameters</h3>
        <AttributesTemplate
          v-for="attribute in pathParams"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <div class="sticky-code">
          <UpdateOrderStatusCurlCommand />
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <h3>Request Body</h3>

        <Required />

        <AttributesTemplate
          v-for="attribute in requestBody"
          :key="attribute.Id"
          :attribute="attribute"
        />

      </v-col>
      <v-col md="6">
        <h3>Request Examples</h3>
        <v-tabs class="sticky-code">
          <v-tab>Marking Successful</v-tab>
          <v-tab>Marking Failed</v-tab>
          <v-tab-item>
            <UpdateOrderStatusFailed />
          </v-tab-item>
          <v-tab-item>
            <UpdateOrderStatusSuccess />
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        <a class="anchor" id="update-order-status-response"></a>
        <router-link to="#update-order-status-response" class="title-anchor">
          <h2>Response</h2>
        </router-link>
        <p>
          If the request was successful, Flyt will respond with
          <code>204 No Content</code> and notify the delivery partner (JustEat, SkipTheDishes, etc.) of the
          order status.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="6">
        <h3>Response Body</h3>
        <AttributesTemplate
          v-for="attribute in responseBody"
          :key="attribute.Id"
          :attribute="attribute"
        />
      </v-col>
      <v-col md="6">
        <v-row>
          <v-col cols="12">
            <h3>Response Examples</h3>
            <v-tabs>
              <v-tab class="code-success">204</v-tab>
              <v-tab class="code-failure">400</v-tab>
              <v-tab-item>
                <pre class="line-numbers">
                  <code class="language-json">
                    No Content
                  </code>
                </pre>
              </v-tab-item>
              <v-tab-item>
                <UpdateOrderStatusRejected />
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AttributesTemplate from "@/components/specs/AttributesTemplate.vue";
import UpdateOrderStatusCurlCommand from "@/components/code/UpdateOrderStatusCurlCommand.vue";
import UpdateOrderStatusFailed from "@/components/code/UpdateOrderStatusSuccess.vue";
import UpdateOrderStatusRejected from "@/components/code/UpdateOrderStatusRejected.vue";
import UpdateOrderStatusSpec from "@/components/specs/UpdateOrderStatus.json";
import UpdateOrderStatusSuccess from "@/components/code/UpdateOrderStatusFailed.vue";
import Required from "@/components/Required.vue";

export default {
  components: {
    AttributesTemplate,
    UpdateOrderStatusCurlCommand,
    UpdateOrderStatusRejected,
    UpdateOrderStatusFailed,
    UpdateOrderStatusSuccess,
    Required
  },
  data: () => UpdateOrderStatusSpec,
};
</script>
