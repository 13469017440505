<template>
  <v-app>
    <v-app-bar elevate-on-scroll color="grey darken-4" app>
      <router-link text to="/" class="title-link"><span class="brand">CONNECT</span>API</router-link>
      <v-spacer></v-spacer>
      <div class="nav d-none d-sm-block">
        <router-link text :to="{path: '/guides'}">Guides</router-link>
        <router-link text :to="{path: '/docs'}">API Docs</router-link>
        <router-link text :to="{path: '/faq'}">FAQ</router-link>
        <!-- <router-link text :to="{name: 'faq'}">FAQ</router-link>
        <router-link text :to="{name: 'golive'}">Go Live</router-link> -->
      </div>
      <div class="d-flex d-sm-none">
        <v-menu offset-y>
          <template v-slot:activator="{ on }">
            <v-btn text v-on="on">
              <v-icon class="menu-icon">fas fa-bars</v-icon>
            </v-btn>
          </template>
          <v-list dense nav class="mobile-nav">
            <v-list-item :to="{path: '/guides'}" class="mobile-nav-link">
              <v-list-item-icon>
                <v-icon>fas fa-graduation-cap</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Guides
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{path:'/docs'}" class="mobile-nav-link">
              <v-list-item-icon>
                <v-icon>fas fa-code</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  API Docs
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <!-- <v-list-item :to="{name: 'faq'}" class="mobile-nav-link">
              <v-list-item-icon>
                <v-icon>fas fa-question-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  FAQ
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item :to="{name: 'golive'}" class="mobile-nav-link">
              <v-list-item-icon>
                <v-icon>fas fa-paper-plane</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Go Live
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </div>
    </v-app-bar>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<style lang="scss">
  @import './styles/main.scss';
</style>

<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism-okaidia.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.css'
import 'prismjs/plugins/line-numbers/prism-line-numbers.js'
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace.js'
import 'prismjs/plugins/toolbar/prism-toolbar.js'
import 'prismjs/plugins/toolbar/prism-toolbar.css'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard.js'
import 'prismjs/plugins/show-language/prism-show-language.js'
import 'prismjs/components/prism-json'
import 'prismjs/components/prism-bash'

export default {
  mounted() {
    Prism.highlightAll()
  },
}
</script>
