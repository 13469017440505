<template>
  <div>
    <pre class="line-numbers">
      <code class="language-json">
        {
          "success": false,
          "errorMessage": "Payload not valid",
          "validationErrors": [
            {
              "dataPath": ".event",
              "message": "should be equal to one of the allowed values"
            }
          ]
        }
      </code>
    </pre>
  </div>
</template>

<script>
  import Prism from 'prismjs'

  export default {
    mounted() {
      Prism.highlightAll()
    }
  } 
</script>
