<template>
  <div class="spec-attribute">
    <code>{{ attribute.name }}</code>
    <span class="required" v-if="attribute.required === true">*</span>
    <span class="type">{{ attribute.type }}</span>
    <p>{{ attribute.description }}</p>
    <PossibleValues
      v-if="attribute.possibleValues !== undefined"
      :possibleValues="attribute.possibleValues"
    />
    <div v-if="attribute.children !== undefined">
      <v-expansion-panels accordion>
        <v-expansion-panel class="sub-attribute">
          <v-expansion-panel-header>
            <span>Attributes (<code>{{ attribute.childType }}</code>)</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <AttributesTemplate
              v-for="childAttribute in attribute.children"
              :key="childAttribute.Id"
              :attribute="childAttribute"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import PossibleValues from "@/components/specs/PossibleValues.vue";

export default {
  components: { PossibleValues },
  name: "AttributesTemplate",
  props: ["attribute"],
};
</script>
