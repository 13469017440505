<template>
  <p>
    <b>Possible Values</b>
    <table class="values">
      <tr v-for="value in possibleValues" :key="value.Id" class="value-item">
        <td><code>{{ value.name }}</code></td>
        <td>{{ value.description }}</td>
      </tr>
    </table>
  </p>
</template>

<script>
export default {
  props: ["possibleValues"],
};
</script>
