<template>
  <div>
    <pre class="line-numbers">
      <code class="language-json">
{
  "type": "delivery-by-merchant",
  "posLocationId": "22617",
  "id": "7494e975-4d24-4ea5-bdd6-306b444ccc51",
  "location": {
    "id": 2267,
    "timezone": "US/Mountain"
  },
  "items": [
    {
      "name": "Cheesy Pasta",
      "description": "",
      "plu": "MMcc0",
      "children": [],
      "price": 500,
      "notes": "please make it extra cheesy"
    }
  ],
  "created_at": "1606780145",
  "channel": {
    "id": 52,
    "name": "Skip"
  },
  "deliver_at": "1606780980",
  "delivery_notes": "It's the blue house at the end of the block.",
  "kitchen_notes": "",
  "third_party_order_reference": "207217603",
  "total": 500,
  "payment_method": "CARD",
  "tender_type": "Flyt",
  "menu_reference": "",
  "payment": {
    "items_in_cart": {
      "inc_tax": 600,
      "tax": 100
    },
    "adjustments": [
      {
        "name": "Delivery Fee",
        "price": {
          "inc_tax": 240,
          "tax": 40
        }
      }
    ],
    "final": {
      "inc_tax": 840,
      "tax": 140
    }
  },
  "delivery": {
    "first_name": "John",
    "last_name": "Doe",
    "phone_number": "555-113-0000",
    "line_one": "1234 Spicy Street",
    "line_two": "",
    "city": "Winnipeg",
    "postcode": "R3B 0P4",
    "email": "johndoe@emailaddress.com",
    "coordinates": {
      "longitude": -97.13560152293131,
      "latitude": 49.898498728223224,
      "longitude_as_string": "-122.2966",
      "latitude_as_string": "49.8984"
    },
    "phone_masking_code": ""
  },
  "extras": {},
  "promotions": [
    {
      "type": "FREE_ITEM_MIN_BASKET",
      "items": [
        {
          "name": "Crispy Chicken Twist",
          "description": "",
          "plu": "",
          "children": [],
          "price": 419,
          "notes": ""
        }
      ]
    }
  ]
}
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
