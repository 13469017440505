<template>
  <div>
    <pre class="line-numbers">
        <code class="language-json">
          {
            "event": "OUT_OF_STOCK",
            "itemReference": "5678-1234",
            "happenedAt": "2020-10-11T14:56:18.234284+01:00",
          }
          </code></pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
