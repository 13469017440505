<template>
  <div>
    <pre class="line-numbers code-overflow">
      <code class="language-json">
        {
          "baseline_menus": [
            {
              "name": "Burger Zone Winter Menu",
              "reference": "",
              "type": "DELIVERY",
              "availability": {
                "monday": [
                  "08:00 - 20:00"
                ],
                "tuesday": [
                  "08:00 - 20:00"
                ],
                "wednesday": [
                  "08:00 - 20:00"
                ],
                "thursday": [
                  "08:00 - 20:00"
                ],
                "friday": [
                  "08:00 - 20:00"
                ],
                "saturday": [
                  "00:00 - 00:00"
                ],
                "sunday": [
                  "00:00 - 00:00"
                ]
              },
              "categories": [
                {
                  "name": "Burgers",
                  "description": "Burger Zone's Specialty.",
                  "items": [
                    {
                      "name": "Cheeseburger",
                      "description": "The classic burger, but with cheese added.",
                      "plu": "XYZ123",
                      "modifiers": [
                        {
                          "name": "Cheese",
                          "description": "The type of cheese on the burger.",
                          "options": [
                            {
                              "name": "Cheddar,",
                              "description": "",
                              "plu": "2B",
                              "modifiers": [],
                              "includes": [],
                              "price": 0,
                              "ingredients": [],
                              "reference": "",
                              "gallery": []
                            },
                            {
                              "name": "Swiss,",
                              "description": "",
                              "plu": "A2",
                              "modifiers": [],
                              "includes": [],
                              "price": 100,
                              "ingredients": [],
                              "reference": "",
                              "gallery": []
                            }
                          ],
                          "pick": {
                            "pick_same_option": false,
                            "exactly": 1
                          }
                        }
                      ],
                      "includes": [],
                      "price": 1700,
                      "ingredients": [],
                      "reference": "",
                      "gallery": []
                    }
                  ]
                }
              ]
            }
          ]
        }
      </code>
    </pre>
  </div>
</template>

<script>
import Prism from "prismjs";

export default {
  mounted() {
    Prism.highlightAll();
  },
};
</script>
